<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="sfLists" :loading="loading" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="sfLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/SFSJDetail', query: { id: record.NENGHAO_DATA_ID, num: record.xuhao, type: 'edit' } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="add-item">
                <div @click="$router.push({ path: '/ReportForms/SFSJDetail', query: { type: 'add', id: '', num: '' } })">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;">增加一行
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
  { title: '序号', width: 100, align: 'center', dataIndex: 'xuhao', key: 'xuhao' },
  { title: '日期', align: 'center', dataIndex: 'riqi', key: 'riqi' },
  { title: '用水量', align: 'center', dataIndex: 'yongshuiliang', key: 'yongshuiliang' },
  { title: '水费', align: 'center', dataIndex: 'shuifei', key: 'shuifei' },
  { title: '税率', align: 'center', dataIndex: 'shuilv', key: 'shuilv' },
  { title: '税额',align: 'center', dataIndex: 'shuie', key: 'shuie' },
  { title: '备注', align: 'center', dataIndex: 'beizhu', key: 'beizhu' },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 100,
    slots: { customRender: 'action' }
  }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getSFList(this.y)
            }
        },
        y: function (newVal) {
          if (newVal) {
            this.getSFList(this.y)
          }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            sfLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取水费列表
        getSFList (y) {
            this.loading = true
            this.sfLists = []
            this.$store.dispatch('getNHSJList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, CTYPE: 'shuifei' }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.sfLists.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'NENGHAO_DATA_ID': datas[i].NENGHAO_DATA_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'riqi': datas[i].D_DATE,
                                'yongshuiliang': datas[i].USECOUNT,
                                'shuifei': datas[i].USEMONEY,
                                'shuilv': datas[i].SHUILV,
                                'shuie': datas[i].SHUIE,
                                'beizhu': datas[i].REMARK
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.sfLists = []
                    }
                } else {
                    this.pagination.total = 0
                    this.sfLists = []
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            }).catch(e => {
                this.loading = false
                this.$message.error('系统异常，获取列表失败，请稍后重试！')
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getSFList(this.y)
        },
        // 删除单条水费数据
        onDel (item) {
            this.$store.dispatch('deleteNHSJItem', { NENGHAO_DATA_ID: item.NENGHAO_DATA_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getSFList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getSFList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .add-item{
            margin-top: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>
